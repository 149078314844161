import * as mutationTypes from '@/store/mutations-types.js'
import reloadDataTypes from '@/shared/enums/reload_data_types.js'
import * as hierarchy from '@/shared/enums/hierarchy.js'
import borderListTypes from '@/enums/borderList/borderListTypes.js'
import periodUnits from '@/shared/enums/period_units.js'

async function changeHierarchy(store, newHierarchy, periodUnit = null) {
  if (store.state.hierarchy.id !== newHierarchy.id) {
    await store.dispatch('addEntryHistorySections', { asSection: null, sections: [], hierarchy: newHierarchy})
    await store.dispatch('circle/getDataRepresentation', {
      unitPerCircle: store.state.circle.unitPerCircle,
      periodUnit: periodUnit || store.state.circle.periodUnit,
      reloadDataTypes: reloadDataTypes.CATEGORISATION
    })
  }
}

async function diabGlobal(store) {
  const newHierarchy = {
    type: hierarchy.type.SYSTEM,
    id: hierarchy.hierarchy.DIABETOLOGIE
  }
  await changeHierarchy(store, newHierarchy)
}

async function diabNurseEntry(store) {
  const newHierarchy = {
    type: hierarchy.type.SYSTEM,
    id: hierarchy.hierarchy.DIABETOLOGIE_NURSE_ENTRY
  }
  store.commit(mutationTypes.SET_SELECTED_PERIOD, null)
  store.commit(`${mutationTypes.IS_EDIT_MODE}`, false)
  await changeHierarchy(store, newHierarchy, periodUnits.QUARTER)
  await store.dispatch('event/common/controlEvent', {isEnabled: true, typeEventslist: borderListTypes.LIST_NURSE_ENTRY})
}

async function diabNurseEntryTrt(store) {
  const newHierarchy = {
    type: hierarchy.type.SYSTEM,
    id: hierarchy.hierarchy.DIABETOLOGIE_NURSE_ENTRY_TRAITEMENT
  }
  store.commit(mutationTypes.SET_SELECTED_PERIOD, null)
  store.commit(`${mutationTypes.IS_EDIT_MODE}`, false)
  await changeHierarchy(store, newHierarchy, periodUnits.QUARTER)
  await store.dispatch('event/common/controlEvent', {isEnabled: true, typeEventslist: borderListTypes.LIST_NURSE_ENTRY})
}

export {
  diabGlobal,
  diabNurseEntry,
  diabNurseEntryTrt
}