import tmpcirclesConfig from '../../config/circle.js'
import { themeConfig } from '../../config/circle.js'
import * as mutationTypes from '../mutations-types.js'
import * as d3 from 'd3'
import * as _ from 'lodash'
import periodUnits from '@/shared/enums/period_units.js'
import reloadDataTypes from '@/shared/enums/reload_data_types.js'
import eventManager from '@/libraries/EventManager.js'
import categorisationAnimation from '@/animation/categorisation.js'
import temporalityCircleAnimation from '@/animation/temporalityCircle.js'
import temporality from '@/animation/temporality.js'
import introAnimation from '@/animation/intro.js'
import CircleUtility from '@/libraries/CircleUtility.js'
import loadingAnimation from '@/animation/loading.js'
import EyeFetch from '@/libraries/EyeFetch.js'

import ErrorManager from '@/libraries/ErrorManager.js'

const getDefaultState = () => {
  return {
    /**
     * Il s'agit d'option de debug
     * @type {Object}
     */
    debug: {
      typeBackAlgo: 'minPointSubdvided',
      isSubdivideEnable: false,
      alignment: true,
    },
    /**
     * Il s'agit des informations des différents cercles représentant les périodes temporelles
     * @type {EyeCircle[]}
     */
    circles: [],
    /**
     * Il s'agit des données du cercle actuellement survolé par l'utilisateur
     * @type {null|EyeCircle}
     */
    hoveredCircle: null,
    /**
     * Il s'agit du nombre d'unité de temps utilisée pour chaque cercle
     * @type {Number}
     */
    unitPerCircle: 1,
    /**
     * Il s'agit de l'unité de temps utilisée pour chaque cercle
     * @type {EyeEnumPeriodUnit}
     */
    periodUnit: periodUnits.YEAR,
    /**
     * Il s'agit du nombre d'années optimal par cercle pour l'affichage de la représentation
     * @type {Number}
     */
    optimalYearPerCircle: 0,
    /**
     * Indique si les cercles sont en train de se déplacer via une transition
     * @type {Boolean}
     */
    circlesMoving: false,
    /**
     * Tableau contenant les configurations d'affichage devant s'appliquer aux cercles affichés
     * @type {Boolean}
     */
    circlesConfig: tmpcirclesConfig,
    /**
     * Tableau contenant les informations des précédents cercles séléctionnés par l'utilisateur (historique cercle de navigation). Dans la mesure où l'utilisateur est toujours redirigé sur le cercle précédent, le tableau n'a pas vraiment d'intéret. Il n'est pas possible de remonter plus loin que n-1
     * @type {Array}
     */
    previous: [],
    /**
     * Couleur du cercle de navigation
     * @type {String}
     */
    repCircleColor: localStorage.getItem("repCircleColor") ? localStorage.getItem('repCircleColor') : "#95CD41",
    /**
     * Lors d'un changement de temporalité depuis un cercle bien précis, cette variable contient les informations du cercle depuis lequel le changement de temporalité a été initié
     * @type {String}
     */
    temporalityMenuSelectedCircle: null,
    /**
     * Détermine si seul le cercle principal doit être affiché
     * @type {Boolean}
     */
    hide: false,
    /**
     * Définit les filtres s'appliquant aux cercles
     * @type {Object}
     */
    filters: {
      /**
       * Permet d'afficher uniquement les sections contenant des événements
       * @type {Boolean}
       */
      sectionsEventsOnly: false,
      /**
       * Permet d'afficher uniquement les événements qui match avec le score actuellement séléctionné par l'utilisateur
       * @type {Boolean}
       */
      matchOnly: false,
      events: {
        text: '',
        codes: [],
        pmsi: {}
      }
    },
    isHoveredRepCircle: false
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    circles: state => {
      if (state.hide) {
        return state.circles.filter(circle => circle.active === true);
      } else {
        return state.circles;
      }
    },
    displayedCircles: state => {
      if (state.hide) {
        return state.circles.filter(event => event.active === true)
      } else {
        return state.circles.filter(circle => CircleUtility.isVisibleCircle(circle.configIndex))
      }
    },
    unitPerCircle: state => state.unitPerCircle,
    periodUnit: state => state.periodUnit,
    optimalYearPerCircle: state => state.optimalYearPerCircle,
    isCirclesMoving: state => state.circlesMoving,
    theme: state => state.circlesConfig,
    repCircleColor: state => state.repCircleColor,
    hide: state => state.hide,
    hoveredCircle: state => state.hoveredCircle,
    isHoveredRepCircle: state => state.isHoveredRepCircle
  },
  mutations: {
    /**
     * Met à jour les informations des cercles représentant les périodes temporelles
     * @param {Number} payload
     * @method
     * @public
     */
    [mutationTypes.UPDATE_CIRCLES](state, payload) {
      state.circles = payload
    },
    /**
     * Met à jour l'état de mouvement des cercles. C'est à dire si les cercles sont en train de se dépacer ou non via une transition
     * @param {Boolean} payload
     * @method
     * @public
     */
    [mutationTypes.UPDATE_STATE_CIRCLE_MOVING](state, payload) {
      state.circlesMoving = payload
    },
    /**
     * Ajoute à l'historique des cercles séléctionnés, le cercle passé en paramètre
     * @param {Circle} payload
     * @method
     * @public
     */
    [mutationTypes.KEEP_PREVIOUS_CIRCLE](state, payload) {
      state.previous[0] = payload
    },
    /**
     * Définit le nombre d'unité de temps utilisé pour chaque cercle / période temporelle
     * @param {Number} payload
     * @method
     * @public
     */
    [mutationTypes.SET_UNIT_PER_CIRCLE](state, payload) {
      state.unitPerCircle = payload
    },
    /**
     * Définit le nombre d'années optimal par cercle pour l'affichage de la représentation
     * @param {Number} payload
     * @method
     * @public
     */
    [mutationTypes.SET_OPTIMAL_YEAR_PER_CIRCLE](state, payload) {
      state.optimalYearPerCircle = payload
    },
    /**
     * Définit la couleur d'affichage du cercle de navigation
     * @param {String} payload
     * @method
     * @public
     */
    [mutationTypes.CHANGE_REP_CIRCLE_COLOR](state, payload) {
      state.repCircleColor = payload
      localStorage.setItem("repCircleColor", payload)
    },
    /**
     * Définit l'unité de temps utilisée pour chaque cercle
     * @param {String} payload
     * @method
     * @public
     */
    [mutationTypes.SET_PERIOD_UNIT](state, payload) {
      state.periodUnit = payload
    },
    /**
     * Permet de définir le cercle depuis lequel un changement de temporalité précis à été initié
     * @param {String} payload
     * @method
     * @public
     */
    [mutationTypes.SET_TEMPORALITY_SELECTED_CIRCLE](state, payload) {
      state.temporalityMenuSelectedCircle = payload
    },
    /**
     * Permet de mettre à jour la variable hide c'est-à-dire celle qui permet de déterminer si seul le cercle actif doit être affiché ou non
     * @param {String} payload
     * @method
     * @public
     */
    [mutationTypes.UPDATE_HIDE_INNER_CIRCLE](state, payload) {
      state.hide = payload
      this.dispatch(`event/common/generateEvents`, null, { root: true })
    },
    /**
     * Permet de mettre à jour les filtres appliqués sur les cercles
     * @param {Objet} payload
     * @method
     * @public
     */
    [mutationTypes.UPDATE_CIRCLE_FILTERS](state, payload) {
      state.filters = payload
    },
    /**
     * Permet de mettre à jour les options de débug
     * @param {Objet} payload
     * @method
     * @public
     */
    [mutationTypes.UPDATE_DEBUG](state, payload) {
      state.debug = payload
    },
    /**
     * Permet de définir le cercle actuellement survolé par l'utilisateur
     * @param {EyeCircle} payload
     * @method
     * @public
     */
    [mutationTypes.SET_HOVERED_CIRCLE](state, payload) {
      state.hoveredCircle = payload
    },
    [mutationTypes.SET_HOVERED_REP_CIRCLE](state, payload) {
      state.isHoveredRepCircle = payload
    }
  },
  actions: {
    /**
     * Permet de remettre le state du module à sa valeur par défaut
     * @param {Objet} payload
     * @method
     * @public
     */
    resetState(context) {
      Object.assign(context.state, getDefaultState())
    },
    getRepresentationReqBody(context, { params, selectedSections }) {
      const body = {
        unitPerCircle: params.unitPerCircle,
        periodUnit: params.periodUnit,
        events: context.rootState.displayedEventsTypes,
        selectedSections: selectedSections,
        filters: context.state.filters,
        dataSourceComposition: context.rootState.patient.dataSourceComposition,
        mode: context.rootState.mode,
        historySections: context.rootState.historySections.slice(0, context.rootState.indexHistorySection + 1),
        hierarchy: context.rootState.hierarchy,
        debug: {
          ...params.debug,
          ...{
            isSubdivideEnable: context.state.debug.isSubdivideEnable,
            typeBackAlgo: context.state.debug.typeBackAlgo,
            alignment: context.state.debug.alignment
          }
        }
      }
      
      return body
    },
    /**
     * Lors d'un changement de temporalité ou une recatégorisation, cette fonction permet de déterminer sur quel cercle l'utilisateur doit être placé. Par exemple, si l'utilisateur était placé sur le cercle 1980 avec une temporalité d'1 an par cercle, il décide de modifier la temporalité à 4 ans par cercle. La fonction déterminera qu'il doit être placé sur le cercle représentant la période de 1980 à 1983.
     * @param {EyePeriod} selectedPeriod Periode sur laquelle l'utilisateur était positionnée
     * @param {EyeCircle[]} periods Nouvelles périodes obtenues depuis le serveur qui seront affichés sur la représentation
     * @param {EyeEnumPeriodUnit} periodUnit Unité de temps utilisé pour chacun des cercle
     * @param {Number} unitPerCircle Nombre d'unité de temps par cercle
     * @method
     * @public
     * @returns {EyeCircle} Cercle correspondant à la période où l'utilisateur était précédemment positioné
     */
    findPreviousSelected(context, { selectedPeriod, periods, periodUnit, unitPerCircle }) {
      /* 
        Les périodes sont ordonnée par date croissante
 
        Si la nouvelle unité de période de temps est plus grande que l'anciene (ex Année plus grande qu'un semestre)
          ou que l'unité de période de temps est la même ET que le nombre d'unité par cercle est plus grand que l'ancienne période de temps (ex: avant 1 an par cercle, maintenant 2 an par cercle)
        Alors on cherche la première période qui englobe l'ancienne période
         | | => nouvelle période
         * * => ancienne période
         |    *     *    |
 
        Sinon on cherche la première période pour laquelle la date de fin devient plus vieille ou égale à la date de fin de la période qui était selectionnée
 
        Si jamais les fonctions de recherche ne trouve aucun résultat capable de répondre aux conditions ci-dessus, le cercle le plus récent est retourné
      */
      let findPeriod = null
      if (periodUnit < selectedPeriod.unit || (periodUnit === selectedPeriod.unit && unitPerCircle > selectedPeriod.unitPerCircle)) {
        findPeriod = periods.find(period =>
          new Date(selectedPeriod.start) >= new Date(period.period.start)
          && new Date(selectedPeriod.end) <= new Date(period.period.end)
        )
      } else {
        findPeriod = _.findLast(periods, period => new Date(period.period.end) <= new Date(selectedPeriod.end))
      }

      if (!findPeriod) {
        return _.last(periods)
      }
      return findPeriod
    },
    /**
     * Cette fonction est appelée lors du chargement de l'application, d'un changement de temporalité ou d'une recatégorisation. Elle permet de faire un appel au back-end pour obtenir les données nécessaires à l'affichage de la représentation. Une fois les données obtenues:
     * Elle détermine l'index de configuration utilisé pour chaque cercle
     * Modifie les états des événements en fonction de s'ils avaient déjà été séléctionnés
     * Met à jour les sections présentes sur la représentation
     * Met à jour la liste des cercles
     * Met à jour la liste des événements
     * Met à jour le nombre d'année optimal pour chaque cercle
     * Met à jour l'unité et le nombre d'unité de temporalité utilisé pour chaque cercle
     * @param {Objet} payload
     * @method
     * @public
     */
    async getDataRepresentation(context, params) {
      let promises = []
      let selectedCircleData = null
      const selectedSections = context.rootState.historySections[context.rootState.indexHistorySection]

      try {
        context.commit(`event/common/${mutationTypes.SET_DISPLAY_EVENTS}`, false, { root: true })
        context.commit(`event/common/${mutationTypes.UPDATE_LABEL_TO_DISPLAY}`, [], { root: true })
        context.commit(mutationTypes.SET_LOADING_REPRESENTATION, true, { root: true })

        loadingAnimation.setIsLoadingData(true)
        if (params.reloadDataTypes === reloadDataTypes.CATEGORISATION) {
          categorisationAnimation.startAnimation(this)
        } else if (params.reloadDataTypes === reloadDataTypes.TEMPORALITY_CIRCLE) {
          temporalityCircleAnimation.startAnimation(this)
        } else if (params.reloadDataTypes === reloadDataTypes.TEMPORALITY) {
          temporality.startAnimation(this)
        } else if (params.reloadDataTypes === reloadDataTypes.INTRO) {
          introAnimation.startAnimation(this)
        }

        const requestBody = await context.dispatch('getRepresentationReqBody', {
          params: params,
          selectedSections: selectedSections
        })

        let response = await EyeFetch(this, `${process.env.VUE_APP_SERVER_BASE_URL}/fhir/data/${context.rootState.patient.idPatient}`, {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify(requestBody),
        })

        if (!response.ok) {
          ErrorManager.requestError(response, {origin: 'circle/getDataRepresentation', params})
          return 
        }

        response = await response.json()

        if (context.rootState.selectedPeriod) {
          selectedCircleData = await context.dispatch('findPreviousSelected', {
            selectedPeriod: context.rootState.selectedPeriod,
            periods: response.periods,
            periodUnit: params.periodUnit,
            unitPerCircle: params.unitPerCircle
          })
        } else {
          selectedCircleData = response.periods.findLast(p => p.futur === false) || response.periods[response.periods.length - 1]
        }

        const indexSelectedCircle = response.periods.findIndex(period => period.id === selectedCircleData.id)
        const indexMainCircleConfig = context.state.circlesConfig.findIndex(config => config.active)
        let circleConfigIndex = indexSelectedCircle * -1 + indexMainCircleConfig

        const periodsData = response.periods.map(circle => {
          circle.configIndex = circleConfigIndex
          circle.active = selectedCircleData.id === circle.id
          circleConfigIndex++
          return circle
        })

        context.commit(`refCircle/familyHistory/${mutationTypes.UPDATE_FAMILY_HISTORY_CIRCLE}`, response.historyFamily, {root: true})
        context.commit(mutationTypes.SET_SELECTED_PERIOD, selectedCircleData.period, { root: true })
        context.commit(mutationTypes.SET_DATA_EXTENSION, response.extension, { root: true })
        context.commit(mutationTypes.SET_UNCLASSIFIED, response.unclassified, { root: true })
        context.commit(mutationTypes.UPDATE_SECTIONS, response.categories, { root: true })
        context.commit(mutationTypes.SET_UNIT_PER_CIRCLE, params.unitPerCircle)
        context.commit(mutationTypes.SET_PERIOD_UNIT, params.periodUnit)
        context.commit(mutationTypes.UPDATE_CIRCLES, periodsData)
        context.commit(mutationTypes.SET_OPTIMAL_YEAR_PER_CIRCLE, response.optimalYearPerCircle)
        context.commit(mutationTypes.SET_EVENTS_PODIUM, response.eventsPodium, { root: true })
        context.rootState.event.common.antecedentRankEvent = d3.rollup(context.rootGetters['event/common/allEvents'], v => v.length, d => d.code)
        eventManager.setAntecedentRank(context.rootState.event.common.antecedentRankEvent)

        context.commit(`event/common/${mutationTypes.SET_EVENT_FILTERS}`, { ...context.rootState.event.common.eventFilters }, { root: true }) //SERT uniquement à trigger le watcher de cette variable dans le composant ListTimeFilter
        context.dispatch('event/common/generateEvents', null, { root: true })
        if (context.rootGetters['refCircle/score/scoreCircle'] !== null) {
          promises.push(
            context.dispatch('refCircle/score/getScoreCircle', null, {root: true})
          )
        }
        if (context.rootState.refCircle.associatedEvents.srcEvent !== null) {
          promises.push(
            context.dispatch('refCircle/associatedEvents/getAssociatedEvents', context.rootState.refCircle.associatedEvents.srcEvent, {root: true})
          )
        }
        loadingAnimation.setIsLoadingData(false)
        await Promise.all(promises)
      } catch (err) {
        console.error(err)
        ErrorManager.fetchError(err)
        loadingAnimation.setIsLoadingData(false)
      }

      if (params.reloadDataTypes === null) {
        context.commit(mutationTypes.SET_LOADING_REPRESENTATION, false, { root: true })
      }
    },
    /**
     * Permet de changer de cercle séléctionné par l'utilisateur / cercle actif.
     * @param {EyeCircle} payload Il s'agit des informations du cercle sur lequel l'utilisateur souhaite se déplacer
     * @method
     * @public
     */
    changeMainCircle(context, { selectedCircleData }) {
      /**
       *  configIndex: 9 => main Circle ( = id number 10)
       * the root key refere to the /store/index.js
       */
      if (context.state.hide || !selectedCircleData || selectedCircleData.active)
        return;

      context.commit(`event/common/${mutationTypes.UPDATE_LABEL_TO_DISPLAY}`, [], { root: true })

      const indexPreviousMainCircle = context.state.circles.findIndex(circle => circle.active)
      const indexMainCircle = context.state.circles.findIndex(circle => circle.id === selectedCircleData.id)

      context.state.circles.map(circle => {
        circle.active = circle.id === selectedCircleData.id
      })

      const moveStep = indexMainCircle - indexPreviousMainCircle
      const updatedCircles = context.state.circles.map(circle => ({
        ...circle,
        ...{ configIndex: circle.configIndex + moveStep * -1 }
      }))
      context.commit(mutationTypes.UPDATE_CIRCLES, updatedCircles)
      context.commit(`event/common/${mutationTypes.SET_DISPLAY_EVENTS}`, false, { root: true })
      context.commit(mutationTypes.UPDATE_STATE_CIRCLE_MOVING, true)
      context.commit(mutationTypes.KEEP_PREVIOUS_CIRCLE, indexPreviousMainCircle)
      context.commit(mutationTypes.SET_SELECTED_PERIOD, selectedCircleData.period, { root: true })
    },
    /**
     * Permet de change le thème de couleurs utilisé pour l'affichage des cercles. Cette fonction agit sur le tableau de configuration des cercles
     * @param {Objet} payload
     * @method
     * @public
     */
    changeTheme(context, payload) {
      localStorage.setItem('theme', payload)
      context.state.circlesConfig = context.state.circlesConfig.map((elt, index) => {
        elt.color = themeConfig[payload][index]
        return elt
      })
    },
    /**
     * Cette fonction est appelée lorsque les cercles ont finit de se déplacer après un changement de cercle principal
     * @param {Objet} payload
     * @method
     * @public
     */
    onCircleStopMove(context) {
      context.commit(`event/common/${mutationTypes.SET_EVENT_FILTERS}`, { ...context.rootState.event.common.eventFilters }, { root: true }) //SERT uniquement à trigger le watcher de cette variable dans le composant ListTimeFilter
      context.dispatch('event/common/generateEvents', null, { root: true })
      context.commit(`event/common/${mutationTypes.SET_DISPLAY_EVENTS}`, true, { root: true })
      context.commit(mutationTypes.UPDATE_STATE_CIRCLE_MOVING, false)
    },
    /**
     * Cette fonction permet de placer l'utilisateur sur le tout premier cercle présentant des événements
     * @method
     * @public
     */
    moveToTheFirst(context) {
      context.dispatch("changeMainCircle", { selectedCircleData: context.state.circles[0] })
    },
    /**
     * Cette fonction permet de placer l'utilisateur sur le dernier cercle présentant des événements
     * @method
     * @public
     */
    moveToTheLast(context) {
      const lastCircle = context.state.circles.findLast(circle => circle.futur === false)
      context.dispatch("changeMainCircle", { selectedCircleData: lastCircle })
    },
    /**
     * Cette fonction permet de déplacer l'utilisateur au cercle suivant (fonctionnalité cercle de navigation)
     * @method
     * @public
     */
    nextCircle(context) {
      let index = context.state.circles.findIndex(elt => elt.active === true) - 1

      if (index < 0) index = 0;
      context.dispatch("changeMainCircle", { selectedCircleData: context.state.circles[index] })
    },
    /**
     * Cette fonction permet de déplacer l'utilisateur au cercle précédent (fonctionnalité cercle de navigation)
     * @method
     * @public
     */
    previousCircle(context) {
      let index = context.state.circles.findIndex(elt => elt.active === true) + 1

      if (index > context.state.circles.length) index = context.state.circles.length;
      context.dispatch("changeMainCircle", { selectedCircleData: context.state.circles[index] })
    },
    /**
     * Cette fonction permet de déplacer l'utilisateur sur le cercle sur lequel il était positionné précédemment
     * @public
     */
    history(context) {
      const x = context.state.previous.pop()
      if (x !== undefined) {
        context.dispatch("changeMainCircle", {selectedCircleData: context.state.circles[x]})
      }
    }
  }
}