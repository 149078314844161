import * as mutationTypes from '@/store/mutations-types.js'
import EyeFetch from '@/libraries/EyeFetch.js'

const getDefaultState = () => {
  return {
    editingEventToAdd: null,
    editingType: null
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    editingType: state => state.editingType,
    editingEventToAdd: state => state.editingEventToAdd
  },
  mutations: {
    [mutationTypes.SET_EDITING_EVENT](state, payload) {
      state.editingEventToAdd = payload
    },
    [mutationTypes.SET_EDITING_TYPE](state, payload) {
      state.editingType = payload
    }
  },
  actions: {
    /**
     * Permet de remettre le state du module à sa valeur par défaut
     * @method
     * @public
     * @param {Context} context Contexte dans lequelle est appelé l'action vuex
     */
    resetState(context) {
      Object.assign(context.state, getDefaultState())
    },
    async deleteEvent(context, { event }) {
      await EyeFetch(this,
        `${process.env.VUE_APP_SERVER_BASE_URL}/patient/event/${event.id}`,
        {
          method: "DELETE",
          credentials: 'include'
        }
      )
    }
  }
}