<template>
  <div id="state-manager">
    <div>
      <div
        v-if="displayTooltipView"
        class="view-tooltip"
      >
        <textarea
          v-model="vue"
          rows="1"
          placeholder="Nom de la vue..."
          @input="onInputViews"
        />
        <div class="flex justify-end mt-2">
          <button @click="onSaveView">
            Enregistrer
          </button>
        </div>
      </div>
      <AddViewIcon
        class="cursor-pointer"
        width="30px"
        height="30px"
        @click="onClickAddView"
      />
    </div>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import AddViewIcon from '@/assets/icons/addView.vue'

export default {
  name: 'VueJS',
  components: {
    AddViewIcon
  },
  data: () => ({
    vue: '',
    displayTooltipView: false
  }),
  computed: {
    ...mapGetters({
      patientData: 'patient/patientData',
      patientViews: 'view/patientViews'
    })
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated',
      saveView: 'view/saveView',
      getAllViews: 'view/getAllViews',
    }),
    async onSaveView(event) {
      if (event.isTrusted) {
        await this.saveView(this.vue)
      }
      this.sendEvent({event: event})
      await this.getAllViews()
      this.displayTooltipView = false
      this.vue = ''
      this.collaborativeEventTreated()
    },
    onClickAddView(event){
      this.sendEvent({event: event})
      this.displayTooltipView = !this.displayTooltipView
      this.collaborativeEventTreated()
    },
    onInputViews(event) {
      if (event.isTrusted) {
        this.sendEvent({
          event: event,
          params: {
            text: this.vue
          }
        })
      } else {
        this.sendEvent({event: event})
      }
      this.collaborativeEventTreated()
    }
  }
}

</script>

<style scoped>
.view-tooltip {
  position: absolute;
  right: 35px;
  bottom: 65px;
  background-color: var(--color-bg-1);
  border: solid;
  border-color: var(--color-border);
  border-width: 1px;
  border-radius: 10px;
  padding: 5px;
}
</style>